import { Breadcrumb, Container } from "react-bootstrap"
import React from "react"
import { getBreadcrumbUrls } from "../common/site/utils"
import { Link } from "gatsby"
import "./Breadcrumbs.scss"
import clsx from "clsx"

const Breadcrumbs = ({ staticURLs, menuData,page,Header_Type}) => {
  let breadcrumbs = staticURLs || getBreadcrumbUrls(menuData)

  if (!breadcrumbs) return null

  return (
    <>
      <div className={`margin-for-header ${Header_Type}`}></div>
      <div className={`breadcrumbs-wrap ${Header_Type}`}>
        <Container className={`breadcrumbs-container ${page==="offplan-detail-page"?"offplan-detail-container":""}`}>
          <Breadcrumb className="breadcrumbs">
            <Breadcrumb.Item className="enable-link-home" href="/">Home</Breadcrumb.Item>
            {breadcrumbs.map((breadcrumb, index) => (
              <Breadcrumb.Item 
              className={`${breadcrumb.label==="Services"? "disable-link-item":""}`}
                key={index}
                active={index === breadcrumbs.length - 1}
              >
                <Link
                  className={clsx(
                    index === breadcrumbs.length - 1 ? "disable-link":  "enable-link"
                  )}
                  to={breadcrumb.url}
                >
                  {breadcrumb.label}
                </Link>
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </Container>
      </div>
    </>
  )
}

export default Breadcrumbs
