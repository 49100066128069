import React from "react"
import TeamDetails from "../components/TeamDetails/TeamDetails"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import { graphql } from "gatsby"
import { aboutURL, teamsURL } from "../site/urls"
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs"
import SEO from "../components/Seo/seo"

const TeamDetailsTemplate = ({ data }) => {
  const teamDetail = data?.glstrapi?.team
  const staticURLs = [
    { url: aboutURL, label: "About" },
    { url: teamsURL, label: "Meet the Team" },
    { url: "", label: teamDetail.name },
  ]
  const title=`${teamDetail?.name} | ${teamDetail?.designation}`
  const description=`Get to know about ${teamDetail?.name} here. Contact one of real estate agents for assistance in finding the right properties for you.`
  return (
    <div className="team-details-page">
      <SEO title={title} description={description} />
      <Header />
      <Breadcrumbs staticURLs={staticURLs} />
      <TeamDetails teamData={teamDetail} />
      <Footer categoryType="category1"/>
    </div>
  )
}

export default TeamDetailsTemplate

export const pageQuery = graphql`
  query GetTeamsPage($articleId: ID!) {
    glstrapi {
      team(id: $articleId) {
        mobile_no
        imagetransforms
        name
        slug
        email
        designation
        content
        image {
          url
        }
        id
      }
    }
  }
`
