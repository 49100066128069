/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"
import { brandSeoSuffix } from "../../site/constants"

function SEO({ description, lang, meta, title, image }) {
  const data = useStaticQuery(
    graphql`
      query GetMetaData {
        glstrapi {
          siteConfig {
            siteMetaData {
              description
              title
              author
            }
          }
        }
      }
    `
  )

  const siteMeta = data.glstrapi.siteConfig.siteMetaData

  const metaTitle = title || siteMeta.title
  const metaDescription = description || siteMeta.description
  var { pathname } = useLocation()

  if (pathname == "/home/" || pathname == "/home") pathname = "/"

  var curentUrl = ""
  curentUrl = process.env.GATSBY_SITE_URL + pathname

  // check trailing slash
  if (!curentUrl.endsWith("/")) curentUrl = curentUrl + "/"

  // remove page- from url
  if (curentUrl.includes("page-")) {
    curentUrl = curentUrl
      .split("/")
      .filter(path => !path.includes("page-"))
      .join("/")
  }

  //remove multiple slashes
  curentUrl = curentUrl.replace(/([^:]\/)\/+/g, "$1")

  //set lowercase url
  curentUrl = curentUrl.toLowerCase()

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={[
        {
          href: curentUrl,
          rel: "canonical",
        },
      ]}
      title={metaTitle}
      titleTemplate={`%s${brandSeoSuffix}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: image ? image : "",
        },
        {
          property: `og:url`,
          content: curentUrl,
        },
        {
          property: `og:image:secure_url`,
          content: image ? image : "",
        },
        {
          property: `og:image:type`,
          content: `image/jpeg`,
        },
        {
          property: `og:image:width`,
          content: `400`,
        },
        {
          property: `og:image:height`,
          content: `300`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: siteMeta?.author || ``,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        // {
        //   name: `twitter:url`,
        //   content: curentUrl,
        // },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `facebook-domain-verification`,
          content: ``,
        },
        // {
        //   property: "twitter:image",
        //   content: image,
        // },{
        //   name: `twitter:card`,
        //   content: `summary_large_image`,
        // },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  image: null,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
}

export default SEO
