import React, { useState } from "react"
import { Button, Container, Modal } from "react-bootstrap"
import { getWhatsAppURL } from "../common/site/utils"
import "./TeamDetails.scss"
import parse from "html-react-parser"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import IndividualTeamForm from "../forms/IndividualTeamForm"
import GetGGFXImage from "../common/site/GetGGFXImage"

const TeamDetails = ({ teamData }) => {
  const { name, mobile_no, designation, email, image, content,id,imagetransforms } = teamData
  const { isMobile, isTablet } = useDeviceMedia()

  const [modalShow, setModalShow] = useState(false)
  const [memberData, setMemberData] = useState("")
  const modalHandler = data => {
    setModalShow(true)
    setMemberData(data)
  }
  const closeModal = () => {
    setModalShow(false)
  }

  let processedImages = imagetransforms?.tile_image_Transforms
  processedImages ||= JSON.stringify({})

  return (
    <>
      <div className="team-details-wrapper">
        {isMobile && (
          <div className="team-details-cta mobile">
            <Button>
              <a href={"tel:" + `${mobile_no}`}>
                <i className="icon white-phone" />
              </a>
            </Button>
            <Button onClick={() => modalHandler(teamData)}>
              {/* <a href="javascript"> */}
              <i className="icon white-envelope" />
              {/* </a> */}
            </Button>
            <Button>
              <a href={getWhatsAppURL(mobile_no)} target="_blank">
                <i className="icon white-Whatsapp" />
              </a>
            </Button>
          </div>
        )}

        {isTablet && (
          <div className="team-image_details_small-device">
            {/* <img src={image?.url} alt={name} /> */}
            <GetGGFXImage
                    imagename={"teams.tile_image.tiledetail"}
                    imagesource={image}
                    fallbackalt={name}
                    imagetransformresult={processedImages}
                    id={id}
                    className=""
                  />
          </div>
        )}
        <Container className="team-details-container">
          <div className="team-side-component">
            <div className="left-side-details">
              <h1 className="team-details-name">{name}</h1>
              <p className="team-details-desigination">{designation}</p>
              {!isMobile && (
                <div className="team-details-cta">
                  <button className="button button-black">
                    <a href={"tel:" + `${mobile_no}`}>
                      <i className="icon white-phone" />
                      <span>CALL</span>
                    </a>
                  </button>
                  <button
                    className="button button-black"
                    onClick={() => modalHandler(teamData)}
                  >
                    <a href="javascript:void(0)">
                      <i className="icon white-envelope" />
                    </a>
                    <span>EMAIL</span>
                  </button>
                  <button className="button button-black">
                    <a href={getWhatsAppURL(mobile_no)} target="_blank">
                      <i className="icon white-Whatsapp" />
                      <span>WHATSAPP</span>
                    </a>
                  </button>
                </div>
              )}
              <div className="team-details-content">{content?parse(content):""}</div>
            </div>
            <div className="right-side-details">
              <div className="team-image_details">
                {/* <img src={image?.url} alt={name} /> */}
                <GetGGFXImage
                    imagename={"teams.tile_image.tiledetail"}
                    imagesource={image}
                    fallbackalt={name}
                    imagetransformresult={processedImages}
                    id={id}
                    className=""
                  />
              </div>
            </div>
          </div>
        </Container>
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="more-filters-modal stay-updated team-listing-modal"
          id="filter-modal"
          // fullscreen={"xl-down"}
        >
          <button
            className="stay-button-form team-listing-form"
            type="button"
            onClick={() => closeModal()}
          >
            <i className="icon cancel-icon" />
          </button>
          <Modal.Body>
            <div className="modal-body-container">
              <IndividualTeamForm memberData={memberData} />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default TeamDetails
